import React from "react"
import { Row } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { PropTypes } from 'prop-types';

ReportBody.propTypes = {
    dataHead: PropTypes.any,
    dataBody: PropTypes.any,
}

function ReportBody({ dataHead, dataBody }) {

    const generateData = () => {
        if (dataHead.length > 0) {
            const data = Object.keys(dataHead).map(key => dataHead[key]).reduce((old, item) => (
                { ...old, ...item }
            ), {})
            const newData = Object.keys(data).reduce((result, currentKey) => {
                if (typeof data[currentKey] === 'string' || data[currentKey] instanceof String) {
                    const elementToPush = generateElement(currentKey, data[currentKey]);
                    result.push(elementToPush);
                }
                return result;
            }, []);
            return newData;
        }
    }
  
    const generateElement = (key, value) => {
        return (
            <Th key={key}>{value}</Th>
        );
    }
    
    const formatCash = (str) => {
        if (str && !isNaN(str)) {
            return str.toString().replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } else {
            return str
        }
    }
  
    const generateDataBody = (data) => {
        if (data) {
            const newData = Object.keys(data).reduce((result, currentKey) => {
                const elementToPush = generateElementBody(currentKey, data[currentKey]);
                result.push(elementToPush);
                return result;
            }, []);
            return newData;
        }
    }
  
    const generateElementBody = (key, value) => {
        //TODO: improve this spaghetti code
        if (key == 'so_ct') {
            
            let url = '';
            if (value.startsWith('HD')) {
                let soCt = value.replace('HD_', '');
                url = `/orders/view/${soCt}`;
            } else if (value.startsWith('TL')) {
                let soCt = value.replace('TL_', '');
                url = `/returns/view/${soCt}`;
            } else if (value.startsWith('PN')) {
                let soCt = value.replace('PN_', '');
                url = `/warehouse-receipts/${soCt}`;
            } else if (value.startsWith('PX')) {
                let soCt = value.replace('PX_', '');
                url = `/warehouse-exports/${soCt}`;
            }

            return (
                <Td key={key} ><a href={url} target="_blank" rel="noreferrer">{value}</a></Td>
            );
        }
        else
            return (
                <Td key={key} >{formatCash(value)}</Td>
            );
    }
  
    const renderDataAllBody = () => {
        if (dataBody.length > 0) {
            return dataBody.map((item, index) => {
                return (
                    <Tr key={index}>
                        {generateDataBody(item)}
                    </Tr>
                )
            })
        }
    }

    return ( 
        <Row>
            <Table className="table table-bordered">
                <Thead className="table-light">
                    <Tr>
                        {
                            generateData()
                        }
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        renderDataAllBody()
                    }
                </Tbody>
            </Table>
        </Row>
    );
}

export default ReportBody;