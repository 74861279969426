import * as commonApis from './commonApis'
import { API_HOST_NAME } from 'constants/SystemConstant';

export const getInventories = (params,callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/inventories/filter';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}
export const getAdvsInventories = (params,callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/inventories/advs-filter';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}
export const getInventoriesDetail = (params,callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/inventories/detail';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}

export const onAddReservations = (params,callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/inventories/reservations';
    let token = localStorage.getItem("token")
    commonApis.POST(url,params, token, "", callback, callbackErr)
}
export const getReservationsDetail = (params,callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/inventories/reservations';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}
export const deleteReservations = (params,callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/inventories/reservations';
    let token = localStorage.getItem("token")
    commonApis.DELETE(url, params, token, '', callback, callbackErr)
}

export const getProductsOutOfStock = async (ids, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/inventories/out-of-stock';
  let token = localStorage.getItem("token")
  return await commonApis.POSTV2(url, ids, token, '', callback, callbackErr)
}