import * as commonApis from './commonApis';
import { API_HOST_NAME } from 'constants/SystemConstant';

export const getListCategory = (selectedShop, page, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/categories"
  if (!selectedShop) return;

  let params = [
    {
      name: 'company_id',
      value: selectedShop
    },
    {
      name: 'page',
      value: page ? page : 1
    },
  ]
  let token = localStorage.getItem("token")
  commonApis.GET(url, token, params, callback, callbackErr)
}

export const getAllCategory = (selectedShop, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/all-categories"
  if (!selectedShop) return;
  let params = [
    {
      name: 'company_id',
      value: selectedShop
    }
  ]
  let token = localStorage.getItem("token")
  commonApis.GET(url, token, params, callback, callbackErr)
}

export const deleteCategory = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/categories/" + id
  let token = localStorage.getItem("token")
  commonApis.DELETE(url, '', token, '', callback, callbackErr)
}

export const getDetailCategory = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/categories/" + id
  let token = localStorage.getItem("token")
  commonApis.GET(url, token, '', callback, callbackErr)
}

export const updateCategory = (selectedShop, id, name, parentId, attribute, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/categories/" + id
  if (!selectedShop) return;
  let body = {
    "company_id": selectedShop
  }
  if (name) {
    body.category_name = name
  }
  if (parentId !== null) {
    body.category_parent_id = parentId
  }
  if (attribute) {
    body.attribute_ids = attribute
  }
  let token = localStorage.getItem("token")
  commonApis.PUT(url, body, token, '', callback, callbackErr)
}

export const createCategory = (selectedShop, name, parentId, attribute, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/categories"
  if (!selectedShop) return;
  let body = {
    "company_id": selectedShop
  }
  if (name) {
    body.category_name = name
  }
  if (parentId) {
    body.category_parent_id = parentId
  }
  if (attribute) {
    body.attribute_ids = attribute
  }
  let token = localStorage.getItem("token")
  commonApis.POST(url, body, token, '', callback, callbackErr)
}


// cms
export const getWebCategories = (params, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/categories/web";
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, params, callback, callbackErr);
};

export const getWebCategory = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/categories/web/' + id;
  let params = []
  let token = localStorage.getItem("token")
  commonApis.GET(url, token, params, callback, callbackErr);
}

export const createWebCategory = (data, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/categories/web';
  let token = localStorage.getItem("token")
  commonApis.POST(url, data, token, '', callback, callbackErr)
}

export const updateWebCategory = (id, data, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/categories/web/' + id;
  let token = localStorage.getItem("token")
  commonApis.PUT(url, data, token, '', callback, callbackErr)
}

export const updateWebCategoryImage = (id, data, callback, callbackErr) => {
  let url = API_HOST_NAME + `/api/categories/web/${id}/image`;
  let token = localStorage.getItem("token")
  commonApis.POST_FILE(url, data, token, '', callback, callbackErr)
}

export const getTreeWebCategory = (params, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/categories/web/tree"
  let token = localStorage.getItem("token")
  commonApis.GET(url, token, params, callback, callbackErr)
}

export const deleteWebCategory = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + '/api/categories/web/' + id;
  let token = localStorage.getItem("token")
  commonApis.DELETE(url, '', token, '', callback, callbackErr)
}