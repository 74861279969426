import { showMessage } from "Utils/global";
import { useState, useEffect } from "react";
import * as productApi from '../../apis/productApi';

const useFetchBrands = (selectedShop) => {
  const [brands, setBrands] = useState([]);  
  
  useEffect(() => {
    if (selectedShop > 0) {
      console.log('start fetch brands');
      productApi.getBrands(selectedShop, fetchBrandsSuccess, showMessage);
    }
  }, [selectedShop])

  const fetchBrandsSuccess = (res) => {
    setBrands(res.data.data.map(item => { return { value: item.id, label: item.brand_name } }));
  }

  return { brands, setBrands };
};

export default useFetchBrands;
