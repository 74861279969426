import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Form, Input, Row, Table, Container, Label } from 'reactstrap'
import ImagesStore from '../../components/ImagesStore/ImagesStore';
import PropTypes from 'prop-types';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { TreeSelect } from "antd";
import * as productApi from '../../apis/productApi';
import * as categoryApi from '../../apis/categoryApi';
import * as attributeApi from '../../apis/attributeApi';
import * as unitApi from '../../apis/unitApi';
import * as wareHouseApi from '../../apis/wareHouseApi';

import Confirm from 'components/Common/Confirm';
import { getMessage } from 'Utils/ErrMessageUtils';
import { utils } from '../../Utils/utils';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { isUserInGroup } from './../../Utils/SecurityUtils';
import PageTitle from 'components/Common/PageTitle';
import MetaTags from 'react-meta-tags';

/**
 * Custom toolbar to remove upload image
 */
class MyEditor extends ClassicEditor {
  static defaultConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
      ]
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },
    language: String
  };
}

function Product(props) {
  const id = props.match.params?.id;
  let isView = props.match.url.includes("view")
  let history = useHistory();

  const listPostedStatus = [
    { value: 'posted_insta', label: 'Instagram' },
    { value: 'posted_fb_24cara', label: 'FB LuxuryMall' },
    { value: 'posted_fb_24cara_hn', label: 'FB LuxuryMall HN' },
    { value: 'posted_fb_24cara_sg', label: 'FB LuxuryMall SG' }
  ]

  const [data, setData] = useState({
    company_id: "",
    product_code: "",
    product_name: "",
    product_web_name: "",
    code_anh: "",
    unit_code: "",
    category_id: '',
    web_category_id: '',
    brand_id: '',
    original_price: "",
    price: "",
    product_desc: "",
    sale_price: "",
    product_content: "",
    supplier_note: "",
    posted_insta: "0",
    posted_fb_24cara: "0",
    posted_fb_24cara_hn: "0",
    posted_fb_24cara_sg: "0",
    atts: "[]",
    open_inventories: "[]"
  })
  const [listCat, setListCat] = useState([]);
  const [listUnit, setListUnit] = useState([]);
  const [listWareHouse, setListWareHouse] = useState([]);
  const [listWareHouseQty, setListWareHouseQty] = useState([]);
  const [webCategories, setWebCategories] = useState([]);
  const [listBrand, setListBrand] = useState([]);

  const [attrs, setAttrs] = useState([])
  const [checkedAttr, setCheckedAttr] = useState([])
  const [addImage, setAddImage] = useState([])
  const [content, setContent] = useState("")
  const selectedShop = useSelector(state => state.SelectedShop)

  const [postedStatus, setPostedStatus] = useState(null);
  const [noQties, setNoQties] = useState(null);

  const showOriginPrice = isUserInGroup(['Admin', 'Kho']);

  const isAdmin = isUserInGroup(['Admin']);
  const isPhotoGroup = isUserInGroup(['Photo']);
  const isContentGroup = isUserInGroup(['Content']);

  useEffect(() => {
    fetchCategory()
    fetchTreeWebCategory()
    fetchUnits()
    fetchBrands()
    if (id) {
      fetchData(id);
    }
    else {
      fetchWareHouse(selectedShop)
    }
  }, [id, selectedShop])

  useEffect(() => {
    if (data.category_id) {
      fetchAttr(data.category_id)
    }
  }, [data.category_id])

  const fetchAttr = (catId) => {
    attributeApi.getAllByCategoryId(catId, fetchAttrSuccess, fetchDataFail)
  }

  const fetchAttrSuccess = (res) => {
    setAttrs(res.data.data)
  }

  const fetchWareHouse = (companyId) => {
    wareHouseApi.getAll(companyId, fetchWareHouseSuccess, fetchWareHouseFail)
  }
  const fetchWareHouseSuccess = (res) => {
    setListWareHouse(res.data.data)
  }
  const fetchWareHouseFail = (res) => {
    alert(getMessage(res.message))
  }

  const fetchUnits = () => {
    unitApi.getAll(fetchUnitsSuccess, fetchUnitsFail);
  }
  const fetchUnitsSuccess = (res) => {
    setListUnit(res.data.data)
  }
  const fetchUnitsFail = (res) => {
    alert(getMessage(res.message))
  }

  const fetchBrands = () => {
    if (!selectedShop) return;
    productApi.getBrands(selectedShop, fetchBrandsSuccess, fetchBrandsFail);
  }
  const fetchBrandsSuccess = (res) => {
    setListBrand(res.data.data.map((v, i) => { return { value: v.id, title: v.brand_name } }));
  }
  const fetchBrandsFail = (res) => {
    alert(getMessage(res.message))
  }

  const fetchCategory = () => {
    categoryApi.getAllCategory(selectedShop, fetchCatSuccess, fetchDataFail)
  }
  const fetchCatSuccess = (res) => {
    setListCat(res.data.data.map((v, i) => { return { value: v.category_id, title: v.category_name } }));
  }

  const fetchTreeWebCategory = () => {
    
    if (!selectedShop) return;

    let params = [
      { name: 'company_id', value: selectedShop },
    ]

    categoryApi.getTreeWebCategory(params, fetchTreeWebCatSuccess, fetchDataFail)
  }
  const fetchTreeWebCatSuccess = (res) => {
    let webCategories = res.data.data
    setWebCategories(webCategories);
  }

  const fetchData = (id) => {
    productApi.get(id, fetchDataSuccess, fetchDataFail)
  }
  const fetchDataSuccess = (res) => {
    const rawData = res.data.data[0]
    if (res.data.data[0].posted_status) {

      rawData.posted_insta = parseInt(res.data.data[0].posted_status.posted_insta)
      rawData.posted_fb_24cara = parseInt(res.data.data[0].posted_status.posted_fb_24cara)
      rawData.posted_fb_24cara_hn = parseInt(res.data.data[0].posted_status.posted_fb_24cara_hn)
      rawData.posted_fb_24cara_sg = parseInt(res.data.data[0].posted_status.posted_fb_24cara_sg)

      const tmpPostedStatus = []

      if (rawData.posted_insta == 1)
        tmpPostedStatus.push({ value: 'posted_insta', label: 'Instagram' })

      if (rawData.posted_fb_24cara == 1)
        tmpPostedStatus.push({ value: 'posted_fb_24cara', label: 'FB LuxuryMall' })

      if (rawData.posted_fb_24cara_hn == 1)
        tmpPostedStatus.push({ value: 'posted_fb_24cara_hn', label: 'FB LuxuryMall HN' })

      if (rawData.posted_fb_24cara_sg == 1)
        tmpPostedStatus.push({ value: 'posted_fb_24cara_sg', label: 'FB LuxuryMall SG' })

      setPostedStatus(tmpPostedStatus)
    }
    if (rawData.web_category_id == 0) rawData.web_category_id = ''

    setData({ ...rawData })
    setCheckedAttr(res.data.data[0].values.map(({ value, ...v }) => v))
  }
  const fetchDataFail = (res) => {
    alert(getMessage(res.message))
  }

  const onChangeNumberInput = (value, attr) => {
    value = parseInt(value.replace(/\D/g, ''));
    onChangeInput(value, attr)
  }

  const onChangeInput = (value, attr) => {
    data[attr] = value;
    setData({ ...data })
  }

  const onChangeWarehouseCategory = (newValue) => {
    data['category_id'] = newValue
    setData({ ...data })
  }

  const onChangeWebCategory = (newValue) => {
    data['web_category_id'] = newValue
    setData({ ...data })
  }

  const onChangeBrand = (newValue) => {
    data['brand_id'] = newValue
    setData({ ...data })
  }

  const onChangePostedStatus = (option) => {
    setPostedStatus(option)
  }

  const showAttrs = () => {
    if (attrs.length === 0) return;
    return attrs.map((v, i) => {
      let chooseValue = checkedAttr.find(e => e.attribute_id == v.attribute_id)
      return (
        <Row xl={12} className="mb-2" key={v.attribute_id}>
          <Label md={12} xl={2} className="col-form-label">{v.attribute_name}</Label>
          <Col md={12} xl={10}>
            <Input
              type='select'
              disabled={isView || isPhotoGroup}
              value={chooseValue?.value_id ? chooseValue?.value_id : ''}
              onChange={e => onChangeCheckedAttr(v.attribute_id, e.target.value)}>
              <option value="">Giá trị</option>
              {showAttrsValueV2(v.values)}
            </Input>
            <Input
              type='text'
              className='mt-1'
              placeholder='Chọn từ danh sách hoặc nhập tay'
              disabled={isView || isPhotoGroup}
              value={chooseValue?.value ? chooseValue?.value : ''}
              onChange={e => onChangeCheckedAttr(v.attribute_id, '', e.target.value)} />
          </Col>
        </Row>
      )
    })
  }
  const showAttrsValueV2 = (datas) => {

    if (datas.length === 0) return;
    return datas.map((v, i) => {
      return (
        <option key={i} value={v.value_id}>
          {v.value}
        </option>
      )
    })
  }
  /**
  * Nếu thêm mới giá trị thì chỉ truyền attrId và label
  * nếu chọn từ giá trị có sẵn thì truyền valueId
  * nếu xóa ô giá trị mới mà không chọn j thì chỉ truyền attrId
  */
  const onChangeCheckedAttr = (attrId, valueId, label = '') => {
    let newCheckedAttr = checkedAttr.filter((v, i) => v.attribute_id != attrId)
    if (label) {
      setCheckedAttr([...newCheckedAttr, { attribute_id: attrId, value: label }])
    }
    else if (valueId) {
      setCheckedAttr([...newCheckedAttr, { attribute_id: attrId, value_id: valueId }])
    }
    else {
      setCheckedAttr([...newCheckedAttr])
    }
  }

  const addImageCallback = (file) => {
    setAddImage([...addImage, ...file]);
  }
  const deleteAddImg = (img) => {
    setAddImage(addImage.filter((v, i) => { return v != img }))
  }
  const onDeleteImg = (filename) => {
    productApi.deleteImg(id, filename, onDeleteImgSuccess, onDeleteImgFail)
  }
  const onDeleteImgSuccess = (res) => {
    fetchData(id)
  }
  const onDeleteImgFail = (res) => {
    alert(getMessage(res.message))
  }

  const onSubmit = (e) => {
    e.preventDefault();
    let data = dataBuilder()
    if (id) {
      productApi.update(id, data, onSubmitSuccess, onSubmitFail)
    }
    else {
      if (data.get('open_inventories') === "[]") {
        setNoQties(1);
      }
      else
        productApi.create(data, onSubmitSuccess, onSubmitFail)
    }
  }

  const onConfirm = () => {
    setNoQties(null);
    let data = dataBuilder()
    productApi.create(data, onSubmitSuccess, onSubmitFail)
  }

  const onSubmitSuccess = (res) => {
    history.push("/products")
  }

  const onSubmitFail = (res) => {
    alert(getMessage(res.message))
  }

  const in_array = (array, value) => {
    if (!array || (array.length == 0)) return false;
    return array.some(function (item) {
      return item.value === value;
    });
  }
  
  const dataBuilder = () => {
    let result = new FormData();

    result.append('company_id', selectedShop)
    result.append('product_code', data.product_code ? data.product_code : '')
    result.append('product_name', data.product_name ? data.product_name : '')
    result.append('product_web_name', data.product_web_name ? data.product_web_name : '')
    result.append('code_anh', data.code_anh ? data.code_anh : '')
    result.append('unit_code', data.unit_code ? data.unit_code : '')
    result.append('category_id', data.category_id ? data.category_id : '')
    result.append('web_category_id', data.web_category_id ? data.web_category_id : '')
    result.append('brand_id', data.brand_id ? data.brand_id : '')
    result.append('original_price', data.original_price ? data.original_price : '')
    result.append('price', data.price ? data.price : '')
    result.append('product_desc', data.product_desc ? data.product_desc : '')
    result.append('sale_price', data.sale_price ? data.sale_price : '')
    result.append('posted_insta', in_array(postedStatus, 'posted_insta') ? '1' : '0')
    result.append('posted_fb_24cara', in_array(postedStatus, 'posted_fb_24cara') ? '1' : '0')
    result.append('posted_fb_24cara_hn', in_array(postedStatus, 'posted_fb_24cara_hn') ? '1' : '0')
    result.append('posted_fb_24cara_sg', in_array(postedStatus, 'posted_fb_24cara_sg') ? '1' : '0')
    result.append('product_content', content ? content : '')
    result.append('supplier_note', data.supplier_note ? data.supplier_note : '')

    let atts = checkedAttr.map(({ attribute_id, ...v }, i) => {
      return { att_id: attribute_id, ...v }
    })
    result.append('atts', JSON.stringify(atts))
    if (addImage.length != 0) {
      addImage.forEach((v, i) => {
        result.append('images[]', v)
      })
    }

    if (!id) {
      const open_inventories = JSON.stringify(listWareHouseQty)
      result.append('open_inventories', open_inventories)
    }
    return result;
  }

  const renderUnit = () => {
    if (listUnit.length === 0) return;

    return listUnit.map((v, i) => {
      return <option key={i} value={v.unit_code}>{v.unit_name}</option>
    })
  }

  const renderWareHouse = () => {
    if (listWareHouse.length === 0) return (<tr></tr>)
    return listWareHouse.map((v, i) => {
      return (
        <tr key={i}>
          <th>{v.warehouse_code}</th>
          <td>{v.warehouse_name}</td>
          <td>
            <Input type='text'
              className="form-control"
              onChange={e => onChangeWarehouseQuantity(v.warehouse_code, e.target.value)}
            />
          </td>
        </tr>
      )
    })
  }
  const onChangeWarehouseQuantity = (code, value) => {
    if (listWareHouseQty.length === 0) {
      setListWareHouseQty([{
        warehouse_code: code,
        qty: value
      }])
    }
    else {
      let result = listWareHouseQty.filter((v, i) => { return v.warehouse_code != code });
      if (value) {
        result.push({
          warehouse_code: code,
          qty: value
        })
      }
      setListWareHouseQty(result);
    }
  }

  const copyContent = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(content);
  }

  const ConfirmNoQty = (props) => {
    const noQties = props.noQties
    return (noQties ?
      <Confirm
        title={"Bạn chưa nhập số lượng nhập kho cho sản phẩm này!"}
        message="Bạn có muốn tiếp tục?"
        confirmCallback={() => {
          onConfirm();
        }}
        cancelCallback={() => {
          setNoQties(null)
        }}
      />
      : null);
  }

  ConfirmNoQty.propTypes = {
    noQties: PropTypes.object
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Chi tiết sản phẩm</title>
      </MetaTags>
      <Container fluid={true}>
        <PageTitle title="CHI TIẾT SẢN PHẨM"></PageTitle>
        <ConfirmNoQty noQties={noQties} />
        <Row xl={12} className="mb-2">
          <Col lg={12} xl={8}>
            <Card>
              <CardHeader className="bg-transparent border-bottom">Thông tin kho</CardHeader>
              <CardBody>
                <Row xl={12} className="mb-2">
                  <Label md={12} xl={2} className="col-form-label">Mã sản phẩm</Label>
                  <Col md={12} xl={10}>
                    <Input
                      type="text"
                      className="form-control"
                      value={data.product_code}
                      disabled={isView || isPhotoGroup || isContentGroup}
                      onChange={e => onChangeInput(e.target.value, 'product_code')}
                    />
                  </Col>
                </Row>
                <Row xl={12} className="mb-2">
                  <Label md={12} xl={2} className="col-form-label">Code ảnh</Label>
                  <Col md={12} xl={10}>
                    <Input
                      type="text"
                      className="form-control"
                      value={data.code_anh}
                      disabled={isView || isPhotoGroup}
                      onChange={e => onChangeInput(e.target.value, 'code_anh')}
                    />
                  </Col>
                </Row>
                <Row xl={12} className="mb-2">
                  <Label md={12} xl={2} className="col-form-label">Tên sản phẩm</Label>
                  <Col md={12} xl={10}>
                    <Input
                      type="text"
                      className="form-control"
                      value={data.product_name}
                      disabled={isView || isPhotoGroup || isContentGroup}
                      onChange={e => onChangeInput(e.target.value, 'product_name')}
                    />
                  </Col>
                </Row>
                <Row xl={12} className="mb-2">
                  <Label md={12} xl={2} className="col-form-label">Đơn vị tính</Label>
                  <Col md={12} xl={10}>
                    <Input type='select'
                      value={data && data.unit_code}
                      disabled={isView || isPhotoGroup || isContentGroup}
                      onChange={e => onChangeInput(e.target.value, 'unit_code')}
                    >
                      <option value="">Chọn đơn vị</option>
                      {renderUnit()}
                    </Input>
                  </Col>
                </Row>
                {
                  showOriginPrice ?
                    <Row xl={12} className="mb-2">
                      <Label md={12} xl={2} className="col-form-label">Đơn giá gốc</Label>
                      <Col md={12} xl={10}>
                        <Input
                          type="text"
                          className="form-control"
                          value={data.original_price ? utils.formatNumber(data.original_price) : ''}
                          onChange={e => onChangeNumberInput(e.target.value, 'original_price')}
                          disabled={isView}
                        />
                      </Col>
                    </Row>
                    : null
                }
                <Row xl={12} className="mb-2">
                  <Label md={12} xl={2} className="col-form-label">Đơn giá bán</Label>
                  <Col md={12} xl={10}>
                    <Input
                      type="text"
                      className="form-control"
                      value={data.price ? utils.formatNumber(data.price) : ''}
                      onChange={e => onChangeNumberInput(e.target.value, 'price')}
                      disabled={isView || isPhotoGroup || isContentGroup}
                    />
                  </Col>
                </Row>
                <Row xl={12} className="mb-2">
                  <Label md={12} xl={2} className="col-form-label">ĐG khuyến mãi</Label>
                  <Col md={12} xl={10}>
                    <Input
                      type="text"
                      className="form-control"
                      value={data.sale_price ? utils.formatNumber(data.sale_price) : ''}
                      onChange={e => onChangeNumberInput(e.target.value, 'sale_price')}
                      disabled={isView || isPhotoGroup || isContentGroup}
                    />
                  </Col>
                </Row>
                {
                  showOriginPrice ?
                    <Row xl={12} className="mb-2">
                      <Label md={12} xl={2} className="col-form-label">Ghi chú nhà cung cấp</Label>
                      <Col md={12} xl={10}>
                        <Input
                          type='textarea'
                          disabled={isView}
                          value={data.supplier_note || ""}
                          onChange={e => onChangeInput(e.target.value, 'supplier_note')}>
                        </Input>
                      </Col>
                    </Row>
                    : null
                }
                <Row xl={12}>
                  <Label md={12} xl={2} className="col-form-label">Nhóm SP kho</Label>
                  <Col md={12} xl={10}>
                    <TreeSelect
                      showSearch
                      treeNodeFilterProp='title'
                      style={{
                        width: '100%',
                      }}
                      value={data.category_id}
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: 'auto',
                      }}
                      treeData={listCat}
                      placeholder="Chọn nhóm sp kho"
                      allowClear
                      treeDefaultExpandAll
                      disabled={isView || (!showOriginPrice)}
                      onChange={onChangeWarehouseCategory}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12} xl={4}>
            <Card>
              <CardHeader className="bg-transparent border-bottom">Thuộc tính</CardHeader>
              <CardBody>
                {showAttrs()}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Card>
          <CardHeader className="bg-transparent border-bottom">Thông tin web</CardHeader>
          <CardBody>
            <Row xl={12} className="mb-2">
              <Label md={12} xl={2} className="col-form-label">Tên web</Label>
              <Col md={12} xl={10}>
                <Input
                  type="text"
                  className="form-control"
                  value={data.product_web_name}
                  disabled={isView || isPhotoGroup}
                  onChange={e => onChangeInput(e.target.value, 'product_web_name')}
                />
              </Col>
            </Row>
            <Row xl={12} className="mb-2">
              <Label md={12} xl={2} className="col-form-label">Nhóm SP web</Label>
              <Col md={12} xl={10}>
                <TreeSelect
                  showSearch
                  treeNodeFilterProp='title'
                  style={{
                    width: '100%',
                  }}
                  value={data.web_category_id}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                  }}
                  treeData={webCategories}
                  placeholder="Chọn nhóm sp web"
                  allowClear
                  treeDefaultExpandAll
                  disabled={isView || (!(isContentGroup || isAdmin))}
                  onChange={onChangeWebCategory}
                />
              </Col>
            </Row>
            <Row xl={12} className="mb-2">
              <Label md={12} xl={2} className="col-form-label">Thương hiệu</Label>
              <Col md={12} xl={10}>
                <TreeSelect
                  showSearch
                  treeNodeFilterProp='title'
                  style={{
                    width: '100%',
                  }}
                  value={data.brand_id}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                  }}
                  treeData={listBrand}
                  placeholder="Chọn thương hiệu sản phẩm"
                  allowClear
                  treeDefaultExpandAll
                  disabled={isView || (!(isContentGroup || isAdmin))}
                  onChange={onChangeBrand}
                />
              </Col>
            </Row>
            <Row xl={12} className="mb-2">
              <Label md={12} xl={2} className="col-form-label">Nội dung <a className='btn btn-sm btn-primary' onClick={copyContent}>copy</a></Label>
              <Col md={12} xl={10}>
                <CKEditor
                  editor={MyEditor}
                  disabled={isView || isPhotoGroup}
                  data={data.product_content ? data.product_content : ''}
                  onChange={(event, editor) => {
                    const editorData = editor.getData();
                    setContent(editorData)
                  }}
                />
              </Col>
            </Row>
            <Row xl={12}>
              <Label md={12} xl={2} className="col-form-label">Post MXH</Label>
              <Col md={12} xl={10}>
                <Select
                  isMulti={true}
                  value={postedStatus}
                  options={listPostedStatus}
                  isDisabled={isView || (!isContentGroup)}
                  onChange={onChangePostedStatus} />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row xl={12} className="mb-2">
          <Col md={12} xl={!id ? 6 : 12}>
            <Card>
              <CardHeader className="bg-transparent border-bottom">Ảnh sản phẩm</CardHeader>
              <CardBody xl={6}>
                <ImagesStore
                  data={data.images ? data.images : []}
                  addImageCallback={addImageCallback}
                  addData={addImage}
                  deleteAddImg={deleteAddImg}
                  deleteImg={onDeleteImg}
                  disabled={isView} />
              </CardBody>
            </Card>
          </Col>

          {!id ?
            (
              <Col md={12} xl={6}>
                <Card>
                  <CardHeader className="bg-transparent border-bottom">Số lượng nhập kho</CardHeader>
                  <CardBody xl={6}>
                    <Table className="mytable mb-0 nowrap-table">
                      <thead>
                        <tr>
                          <th>Mã kho</th>
                          <th style={{ width: '99%' }}>Tên kho</th>
                          <th>Số lượng tồn kho</th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderWareHouse()}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            )
            : null
          }
        </Row>


        <div className="d-flex flex-wrap gap-2">
          {isView ?
            (
              <Button
                className="btn btn-secondary w-md"
                style={{ width: "120px" }}
                onClick={() => history.goBack()}
              >
                Quay lại
              </Button>
            )
            :
            (
              <>
                <Button type="submit" onClick={onSubmit} color="primary" className=' w-md'>
                  Lưu
                </Button>
                {" "}
                <Button
                  className="btn btn-secondary w-md"
                  style={{ width: "120px" }}
                  onClick={() => history.goBack()}
                >
                  Quay lại
                </Button>
              </>
            )
          }
        </div>
      </Container>
    </div>
  )
}

Product.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
}
export default withRouter(Product);
