import React from "react";
import { Redirect } from "react-router-dom";


// Authentication related pages
import Login from "../UsePages/Authentication/Login";
import Logout from "../UsePages/Authentication/Logout";
import Register from "../UsePages/Authentication/Register";
import ForgetPwd from "../UsePages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../UsePages/Dashboard/index";

//Pages
import PagesMaintenance from "../UsePages/Utility/pages-maintenance";
import PagesComingsoon from "../UsePages/Utility/pages-comingsoon";
import Pages404 from "../UsePages/Utility/pages-404";
import Pages500 from "../UsePages/Utility/pages-500";


//------------------------------------------------------------
// custom dashboard

//------------------------------------------------------------
// custom inventories
import Inventory from "UsePages/Inventory/Inventory";

//------------------------------------------------------------
// CMS 24cara
import ListBanner from "UsePages/Cms24Cara/Banner/ListBanner";
import BannerView from "UsePages/Cms24Cara/Banner/BannerView";
// custom notes

import ListOrders from "UsePages/Order/ListOrder";
import Order from "UsePages/Order/Order";
import OrderView from "UsePages/Order/OrderView";
import OrderPrint from "UsePages/Order/OrderPrint";

import ListReturn from "UsePages/Return/ListReturn";
import Return from "UsePages/Return/Return";
import ViewReturn from "UsePages/Return/ViewReturn";

import ListPreOrder from "UsePages/PreOrder/ListPreOrder";
import PreOrder from "UsePages/PreOrder/PreOrder";

import ListWareHouseExport from "UsePages/WareHouseExport/ListWareHouseExport";
import WareHouseExport from "UsePages/WareHouseExport/WareHouseExport";
import WareHouseExportTempo from "UsePages/WareHouseExport/WareHouseExportTempo";
import ListWareHouseReceipt from "UsePages/WareHouseReceipt/ListWareHouseReceipt";
import EditWareHouseReceipt from "UsePages/WareHouseReceipt/EditWareHouseReceipt";
import WareHouseReceipt from "UsePages/WareHouseReceipt/WareHouseReceipt";

//------------------------------------------------------------
// custom categories

import ListWebCategory from "UsePages/WebCategory/ListWebCategory";

import ListProductCategory from "UsePages/ProductCategory/ListProductCategory";
import ProductCategory from "UsePages/ProductCategory/ProductCategory";

import ListProduct from "UsePages/Product/ListProduct";
import Product from "UsePages/Product/Product";

import ListWarehouse from "UsePages/Warehouse/ListWarehouse";
import Warehouse from "UsePages/Warehouse/Warehouse";

import ListShop from "UsePages/Shop/ListShop";
import Shop from "UsePages/Shop/Shop";

import ListPaymentMethod from "UsePages/PaymentMethod/ListPaymentMethod";
import PaymentMethod from "UsePages/PaymentMethod/PaymentMethod";

import ListUnit from "UsePages/Unit/ListUnit";
import Unit from "UsePages/Unit/Unit";

import ListCurrency from "UsePages/Currency/ListCurrency";
import Currency from "UsePages/Currency/Currency";

import ListWarehouseExportType from "UsePages/WarehouseExportType/ListWarehouseExportType";
import WarehouseExportType from "UsePages/WarehouseExportType/WarehouseExportType";

import ListWarehouseReceiptType from "UsePages/WarehouseReceiptType/ListWarehouseReceiptType";
import WarehouseReceiptType from "UsePages/WarehouseReceiptType/WarehouseReceiptType";

import ListCustomer from "UsePages/Customer/ListCustomer";
import Customer from "UsePages/Customer/Customer";

//------------------------------------------------------------
// custom system configs

import ListUser from "UsePages/User/ListUser";
import User from "UsePages/User/User";

import ListRole from "UsePages/Role/ListRole";
import Role from "UsePages/Role/Role";

//------------------------------------------------------------
// reports
import BcDoanhSoHeThong from "UsePages/Report/BcDoanhSoHeThong";
import BcDoanhSoNhanVien from "UsePages/Report/BcDoanhSoNhanVien";
import BcDoanhSoNhomSanPham from "UsePages/Report/BcDoanhSoNhomSanPham";
import BcDoanhSoKho from "UsePages/Report/BcDoanhSoKho";

import BcKhoLichSuNhapXuat from "UsePages/Report/BcKhoLichSuNhapXuat";
import BcKhoNhapXuatTon from "UsePages/Report/BcKhoNhapXuatTon";

import BcBanHang from "UsePages/Report/BcBanHang";

import ShopReport from "UsePages/Report/ShopReport";
import WareHouseReport from "UsePages/Report/WareHouseReport";
import SaleReport from "UsePages/Report/SaleReport";
import permission from "UsePages/Permission/permission";
import SystemConfiguration from "UsePages/SystemConfiguration/SystemConfiguration";
import AttributeGroupsV2 from "../UsePages/ProductAttributes/GroupsV2";
import AddBanner from "UsePages/Cms24Cara/Banner/AddBanner";
import EditBanner from "UsePages/Cms24Cara/Banner/EditBanner";
import ListFeedback from "UsePages/Cms24Cara/Feedback/ListFeedback";
import ListSupportRequest from "UsePages/Cms24Cara/SupportRequest/ListSupportRequest";
import FeedbackView from "UsePages/Cms24Cara/Feedback/FeedbackView";
import ListCategoryMenu from "UsePages/Cms24Cara/CategoryMenu/ListCategoryMenu";
import EditCategoryMenu from "UsePages/Cms24Cara/CategoryMenu/EditCategoryMenu";
import ListFooter from "UsePages/Cms24Cara/Footer/ListFooter";
import AddFooter from "UsePages/Cms24Cara/Footer/AddFooter";
import EditFooter from "UsePages/Cms24Cara/Footer/EditFooter";
import WebCategory from "UsePages/WebCategory/WebCategory";
import ProductV2 from "UsePages/Product/ProductV2";
import ListShopAddress from "UsePages/Cms24Cara/ShopAddress/ListShopAddress";
import AddShopAddress from "UsePages/Cms24Cara/ShopAddress/AddShopAddress";
import EditShopAddress from "UsePages/Cms24Cara/ShopAddress/EditShopAddress";
import ListSaleCampaign from "UsePages/SaleCampaign/ListSaleCampaign";
import SaleCampaign from "UsePages/SaleCampaign/SaleCampaign";
import ListWebOrder from "UsePages/Cms24Cara/WebOrder/ListWebOrder";
import WebOrder from "UsePages/Cms24Cara/WebOrder/WebOrder";
import OrderV2 from "UsePages/Order/OrderV2";
import ListBaseProduct from "UsePages/BaseProduct/ListBaseProduct";
import BaseProduct from "UsePages/BaseProduct/BaseProduct";
import UserWarehouse from "UsePages/User/UserWarehouse";
import FindSaleProducts from "UsePages/SaleCampaign/Find/FindSaleProducts";
import OrderPrintWithPrice from "UsePages/Order/OrderPrintWithPrice";
import OrderSeasonPrint from "UsePages/Order/OrderSeasonPrint";
import OrderSeasonPrintWithPrice from "UsePages/Order/OrderSeasonPrintWithPrice";

const RedirectToDashboard = () => <Redirect to="/dashboard" />;

/**
 * This routes is protected route for this project
 */
const myAuthProtectedRoutes = [

	//-------------------------------------------------------------------------
	// Dashboard: Trang tổng hợp thông tin
	{ path: ["/"], exact: true, component: RedirectToDashboard},
	{ path: ["/24cara",'/dashboard'], component: Dashboard},

	//-------------------------------------------------------------------------
	// Inventory: Tra tồn
	{ path: "/inventories", component: Inventory},

	//--------------------- CÁC LOẠI PHIẾU -----------------------------
	// order: Hóa đơn
	{ path: "/orders", component: ListOrders},
	{ path: "/orders/:id", component: Order},
  { path: "/orders/view/:id", component: OrderView },
  { path: "/orders2/print/:id", component: OrderPrint },
  { path: "/orders2/print-price/:id", component: OrderPrintWithPrice },
  { path: "/orders2/print-season/:id", component: OrderSeasonPrint},
  { path: "/orders2/print-season-price/:id", component: OrderSeasonPrintWithPrice },
	{ path: ["/orders2/:id", "/orders2/create", "/orders2/view/:id"], component: OrderV2},

	// return: Trả lại
	{ path: "/returns", component: ListReturn},
	{ path: "/returns/:id", component: Return},
  { path: "/returns/views/:id", component: ViewReturn },

	// preorder: Đặt hàng
	{ path: "/preorders", component: ListPreOrder},
	{ path: ["/preorders/create","/preorders/:id","/preorders/view/:id"], component: PreOrder},

	// warehouse-exports: Phiếu nhập
	{ path: "/warehouse-exports", component: ListWareHouseExport},
	{ path: "/warehouse-exports/:id", component: WareHouseExport},
  { path: "/warehouse-exports-temp/:id", component: WareHouseExportTempo},

	// warehouse-receipts: Phiếu xuất
	{ path: "/warehouse-receipts", component: ListWareHouseReceipt},
	{ path: "/warehouse-receipts/:id", component: WareHouseReceipt},
	{ path: "/warehouse-receipts-edit/:id", component: EditWareHouseReceipt},

	// warehouse-receipts: Attributes
	{ path: "/attribute-groups", component: AttributeGroupsV2},
	//------------------- DANH MỤC ----------------
	{ path: "/web-categories", component: ListWebCategory},
	{ path: ["/web-categories/create", "/web-categories/:id"], component: WebCategory},
	// product_categories: Nhóm sản phẩm
	{ path: "/product-categories", component: ListProductCategory},
	{ path: ["/product-categories/create", "/product-categories/:id"], component: ProductCategory},

	// products: Sản phẩm
	{ path: "/products", component: ListProduct},
	{ path: ["/products/create", "/products/:id","/products/view/:id"], component: ProductV2},

	// products: Sản phẩm cơ sở
	{ path: "/base-products", component: ListBaseProduct},
	{ path: ["/base-products/:id","/base-products/view/:id"], component: BaseProduct},

	// warehouses = kho hang
	{ path: "/warehouses", component: ListWarehouse},
	{ path: ["/warehouses/create", "/warehouses/:code"], component: Warehouse},

	// shop = Cửa hàng
	{ path: "/shops", component: ListShop},
	{ path: ["/shops/create", "/shops/:id"], component: Shop},

	// payment method
	{ path: "/payment-methods", component: ListPaymentMethod},
	{ path: ["/payment-methods/create","/payment-methods/:code"], component: PaymentMethod},

	// unit
	{ path: "/units", component: ListUnit},
	{ path: ["/units/create","/units/:code"], component: Unit},

	// currencies
	{ path: "/currencies", component: ListCurrency},
	{ path: ["/currencies/create","/currencies/:code"], component: Currency},

	// warehouse export types: Loại phiếu xuất
	{ path: "/warehouse-export-types", component: ListWarehouseExportType},
	{ path: ["/warehouse-export-types/create","/warehouse-export-types/:id"], component: WarehouseExportType},

	// warehouse receipt types: Loại phiếu nhập
	{ path: "/warehouse-receipt-types", component: ListWarehouseReceiptType},
	{ path: ["/warehouse-receipt-types/create","/warehouse-receipt-types/:id"], component: WarehouseReceiptType},

	{ path: "/customers", component: ListCustomer},
	{ path: ["/customers/create","/customers/:id"], component: Customer},

	//report
	{ path: "/reports/sales", component: SaleReport},
	{ path: "/reports/warehouses", component: WareHouseReport},
	{ path: "/reports/shops", component: ShopReport},

  { path: "/reports/dsht", component: BcDoanhSoHeThong},
  { path: "/reports/dsnv", component: BcDoanhSoNhanVien},
  { path: "/reports/dsnsp", component: BcDoanhSoNhomSanPham},
  { path: "/reports/dskho", component: BcDoanhSoKho},
  
  { path: "/reports/kholsnx", component: BcKhoLichSuNhapXuat},
  { path: "/reports/khonxt", component: BcKhoNhapXuatTon},
  
  { path: "/reports/banhang", component: BcBanHang},

	{ path: "/sale-campaigns/find", component: FindSaleProducts},
	{ path: "/sale-campaigns", component: ListSaleCampaign},
  { path: ["/sale-campaigns/create", "/sale-campaigns/:id", "/sale-campaigns/view/:id"], component: SaleCampaign},

  // CMS 24cara
  // banner
  { path: "/banner", component: ListBanner},
  { path: "/banner/create", component: AddBanner},
	{ path: "/banner/edit/:id", component: EditBanner},
  { path: "/banner/view/:id", component: BannerView },

  // request
  { path: "/support-request", component: ListSupportRequest},
  // feedback
  { path: "/feedback", component: ListFeedback},
  { path: "/feedback/view/:id", component: FeedbackView },
  // shop-address
  { path: "/shop-address", component: ListShopAddress},
  { path: "/shop-address/create", component: AddShopAddress},
	{ path: "/shop-address/edit/:id", component: EditShopAddress},

  // category shop
  { path: "/category-menu", component: ListCategoryMenu},
	{ path: "/category-menu/edit/:id", component: EditCategoryMenu},

  // footer
  
	{ path: "/footer-menu", component: ListFooter},
  { path: "/footer-menu/create", component: AddFooter},
	{ path: "/footer-menu/edit/:id", component: EditFooter},

  { path: "/web-orders", component: ListWebOrder},
	{ path: ["/web-orders/:id","/web-orders/view/:id"], component: WebOrder},
  
	// users
	{ path: "/users", component: ListUser},
	{ path: ["/users/create","/users/:id"], component: User},
	{ path: ["/user-warehouses"], component: UserWarehouse},

	// roles
	{ path: "/roles", component: ListRole},
	{ path: ["/roles/create","/roles/:id"], component: Role},

	//permission
	{ path: "/permissions", component: permission},
  // system-configuration
  { path: "/system-configuration", component: SystemConfiguration},
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
];

export { publicRoutes, myAuthProtectedRoutes };
