import * as commonApis from "./commonApis";
import { API_HOST_NAME } from "constants/SystemConstant";

export const getList = (params, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/products";
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, params, callback, callbackErr);
};
export const deleteProduct = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/products/" + id;
  let token = localStorage.getItem("token");
  commonApis.DELETE(url, "", token, "", callback, callbackErr);
};
export const deleteImg = (id, filename, callback, callbackErr) => {
  let url = API_HOST_NAME + `/api/products/${id}/image`;
  console.log(id);
  console.log(filename);
  if (!filename || !id) return;
  let param = [
    {
      name: "image_file",
      value: filename,
    },
  ];
  let token = localStorage.getItem("token");
  commonApis.DELETE(url, "", token, param, callback, callbackErr);
};

export const exportProducts = (params, callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/products/export';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}

export const get = (id, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/products/" + id;
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, "", callback, callbackErr);
};
export const create = (data, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/products";
  let token = localStorage.getItem("token");

  commonApis.POST_FILE(url, data, token, "", callback, callbackErr);
};
export const update = (id, data, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/products/" + id;
  let token = localStorage.getItem("token");

  commonApis.POST_FILE(url, data, token, "", callback, callbackErr);
};

export const uploadFileExcel = (data, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/products/import";
  let token = localStorage.getItem("token");

  commonApis.POST_FILE(url, data, token, "", callback, callbackErr);
};

export const uploadContent = (data, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/products/import-content";
  let token = localStorage.getItem("token");

  commonApis.POST_FILE(url, data, token, "", callback, callbackErr);
};

export const uploadUpdateProductExcel = (data, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/products/import/update";
  let token = localStorage.getItem("token");

  commonApis.POST_FILE(url, data, token, "", callback, callbackErr);
};

export const uploadMultipleImg = (data, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/v2/products/images";
  let token = localStorage.getItem("token");

  commonApis.POST_FILE(url, data, token, "", callback, callbackErr);
};

export const uploadMultipleTempImg = (data, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/products/images?is_temp=1";
  let token = localStorage.getItem("token");

  commonApis.POST_FILE(url, data, token, "", callback, callbackErr);
};

export const getBrands = (companyId, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/brands?company_id=" + companyId;
  let token = localStorage.getItem("token");
  commonApis.GET(url, token, "", callback, callbackErr);
};

export const addSetItem = (data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/products/sets';
    let token = localStorage.getItem("token")
    commonApis.POST(url, data, token, '', callback, callbackErr)
}

export const updateSetItem = (data, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/products/sets/' + data.id;
    let token = localStorage.getItem("token")
    commonApis.PUT(url, data, token, '', callback, callbackErr)
}

export const deleteSetItem = (id, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/products/sets/' + id;
    let token = localStorage.getItem("token")
    commonApis.DELETE(url, '', token, '', callback, callbackErr)
}

export const getProductsByCodes = async (codes, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/products/ids';
    let token = localStorage.getItem("token")
    return await commonApis.POSTV2(url, codes, token, '', callback, callbackErr)
}

export const getProductsByCodeAnh = (codeAnh, callback, callbackErr) => {
    let url = API_HOST_NAME + '/api/products/code-anh';
    let token = localStorage.getItem("token")
    let param = [{ name: 'code_anh', value: codeAnh }];
    return commonApis.GET(url, token, param, callback, callbackErr)
}

export const saveImageOrders = (id, orders, callback, callbackErr) => {
  let url = API_HOST_NAME + `/api/products/${id}/image-order`;
  let token = localStorage.getItem("token");
  commonApis.PUT(url, orders, token, param, callback, callbackErr);
};

export const getV2 = (id, companyId, callback, callbackErr) => {
  let url = API_HOST_NAME + "/api/v2/products";
  let token = localStorage.getItem("token");
  let params = [
    { name: "product_id", value: id },
    { name: "company_id", value: companyId },
  ];
  commonApis.GET(url, token, params, callback, callbackErr);
};