
export const formStateAction = {
  LOADING: 'LOADING',
  SAVING: 'SAVING',
  LOAD_SUCCESS: 'LOAD_SUCCESS',
  LOAD_FAILED: 'LOAD_FAILED'
}

export const formStateReducer = (formState, action) => {
  switch (action.type) {
    case formStateAction.LOADING:
      return {...formState, 
        saving: false,
        loading: true,
        hasError: false,
        message: ''
      };
    case formStateAction.SAVING:
      return {...formState, 
        saving: true,
        loading: false,
        hasError: false,
        message: ''
      };
    case formStateAction.LOAD_SUCCESS:
      return {...formState,
        saving: false,
        loading: false,
        hasError: false,
        message: action.payload
      };
    case formStateAction.LOAD_FAILED:
      return {...formState, 
        saving: false,
        loading: false,
        hasError: true,
        message: action.payload
      };
    default:
      return formState;
  }
}