import * as commonApis from './commonApis'
import { API_HOST_NAME } from 'constants/SystemConstant';

export const getDataTable = (callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/reports/dsnv';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, '', callback,callbackErr);
}

export const getDataReportsDsht = (params, callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/reports/dsht';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}
export const getDataReportsDsnv = (params, callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/reports/dsnv';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}
export const getDataReportsLichSuNhapXuat = (params, callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/reports/lichsunhapxuat';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}
export const getDataReportsDsnsp = (params, callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/reports/dsnsp';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}
export const getDataReportsDsKho = (params, callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/reports/dskho';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}
export const downloadFile = (params, callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/reports/baocaobanhang';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}
export const downloadReportBcBanHang = (params, callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/reports/baocaobanhang';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}
export const getDataReportBcbh = (url, callback,callbackErr) => {
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, '', callback,callbackErr);
}
export const getDataReportNhapXuatTon = (params, callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/reports/nxt';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}
export const downloadReportNhapXuatTon = (params, callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/reports/nxt?download=1';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}
export const getDataReportNhapXuatTonV2 = (params, callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/v2/reports/nxt';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}
export const downloadReportNhapXuatTonV2 = (params, callback,callbackErr) => {
    let url = API_HOST_NAME + '/api/v2/reports/nxt?download=1';
    let token = localStorage.getItem("token")
    commonApis.GET(url, token, params, callback,callbackErr);
}



