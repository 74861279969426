import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Form, Input, Label, Row } from 'reactstrap'
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';
import * as shopApi from '../../apis/shopApi';
import * as roleApi from '../../apis/roleApi';
import AlertSimple from 'components/Common/AlertSimple';
import { utils } from 'Utils/utils';
import MetaTags from 'react-meta-tags';


Role.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
}

Role.defaultProps = {
  location: {},
  match: {}
}

function Role(props) {
  let history = useHistory();
  const id = props.match.params?.id;
  const [data, setData] = useState({});
  const [selectedCompany, setSelectedCompany] = useState({})
  const [ListCompany, setListCompany] = useState([]);
  const [listModule, setListModule] = useState([]);
  const [listAction, setListAction] = useState([]);
  const [moduleAction, setModuleAction] = useState([]);
  const [activeModule, setActiveModule] = useState(0);
  const moduleChange = useRef("");
  const [role, setRole] = useState([]);

  const [showMessage, setShowMessage] = useState({
    state: false,
    message: "",
    title: "",
    typeShow: 'success'
  });

  useEffect(() => {
    loadInit();
  }, []);


  const loadInit = () => {
    if (id) {
      roleApi.get(id, onRoleGetSuccess, errorHandler);
    } else {
      roleApi.getModule(onModuleSuccess, errorHandler);
    }

    shopApi.getAllShop(onShopSuccess, errorHandler);
  }

  const onRoleGetSuccess = (res) => {
    try {
      let result = res.data.data;
      setData(result);
      setSelectedCompany({ value: result.company.company_id, label: result.company.company_name });
      setListModule(result.module_all)
      createRole(result.module_all, result.actions);

      //auto selet module first
      displayTmp(result.module_all[0], result.actions)

    } catch (ex) {
      errorException(ex);
    }
  }

  const displayTmp = (mod, actions) => {
    moduleChange.current = mod.module_id;
    setActiveModule(mod.module_id);

    let result = mod.actions;
    result.forEach(it => {
      let index = actions.findIndex(y => y.action_id == it.action_id && y.module_id == it.module_id);
      it['checked'] = index >= 0;

    })
    setListAction(result);

  }



  const createRole = (modules, actions) => {
    try {
      let rs = [];
      modules.forEach(it => {
        let moduleId = it.module_id;
        let action = [];
        actions.forEach(itt => {
          if (moduleId == itt.module_id) action.push(itt.action_id);
        })

        rs.push({
          module_id: moduleId,
          action_ids: action
        })
      });
      setRole(rs);


    } catch (ex) {
      errorException(ex)
    }
  }


  const onShopSuccess = (res) => {
    setListCompany(res.data.data.map((v, i) => { return { value: v.company_id, label: v.company_name } }));
  }
  const onModuleSuccess = (res) => {
    let result = res.data.data;
    setListModule(result);
    createRoleDefault(result);

    //auto selet module first    
    moduleChange.current = result[0].module_id;
    setActiveModule(result[0].module_id);
    roleApi.getAction(result[0].module_id, onActionDfSuccess, errorHandler);
  }

  const onActionDfSuccess = (res) => {
    //create temp view actions
    let result = res.data.data.actions;
    result.forEach(it => {
      it['checked'] = false;
    });
    setListAction(result);

    //create temp module action
    let mdAc = [...moduleAction];
    mdAc.push({
      module_id: moduleChange.current,
      actions: result
    })
    setModuleAction(mdAc);
  }

  const onActionSuccess = (res) => {
    try {
      //update view actions
      let result = res.data.data.actions;
      createActionView(result);

      //create temp module action
      let mdAc = [...moduleAction];
      mdAc.push({
        module_id: moduleChange.current,
        actions: result
      })
      setModuleAction(mdAc);

    } catch (ex) {
      errorException(ex);
    }
  }

  const createActionView = (result) => {
    let role_vl = [...role];
    result.forEach(it => {
      let itRole = role_vl.find(x => x.module_id == it.module_id);
      let actions = itRole.action_ids;

      let index = actions.findIndex(y => y == it.action_id);
      it['checked'] = index >= 0;

    })
    setListAction(result);
  }

  const errorHandler = (res) => {
    setShowMessage({ state: true, message: res.message, title: "Thất bại", typeShow: 'danger' });
  }

  const errorException = (mes) => {
  }

  const createRoleDefault = (data) => {
    let rs = [];
    data.forEach(it => {
      rs.push({
        module_id: it.module_id,
        action_ids: []
      })
    });
    setRole(rs);
  }

  const onCheckChange = (e, it) => {
    try {
      let check = e.target.checked;
      let role_vl = [...role];
      let listAction_vl = [...listAction];
      //set value của listAction
      listAction_vl.forEach(item => {
        if (item.module_id == it.module_id && item.action_id == it.action_id) item.checked = check;
      });

      setListAction(listAction_vl);
      //set value role
      role_vl.forEach(itt => {
        if (itt.module_id == it.module_id) {
          let actions = itt.action_ids;
          let index = actions.findIndex(y => y == it.action_id);
          if (index < 0 && check) (itt.action_ids).push(it.action_id);
          if (index >= 0 && !check) (itt.action_ids).splice(index, 1);

        }
      });
      setRole(role_vl);
    } catch (ex) {
      errorException(ex);
    }
  }

  const renderModule = () => {
    if (listModule.length === 0) return (<tr></tr>)
    return listModule.map((v, i) => {
      let activeClass = (activeModule === v.module_id) ? 'active' : ''
      return (
        <div key={i} className={'role-permission-item font-weight-bold ' + activeClass} onClick={(e) => { onModuleChange(e, v) }}>
          {v.module_name}
        </div>

      )
    })
  }

  const onModuleChange = (e, it) => {
    if (e) e.preventDefault();
    moduleChange.current = it.module_id;
    setActiveModule(it.module_id);
    if (!id) {
      if (moduleAction.length > 0) {
        let mdAc = moduleAction.find(x => x.module_id == it.module_id);
        if (mdAc) {
          //update view actions
          let listAction = mdAc.actions;
          createActionView(listAction);
        } else {
          roleApi.getAction(it.module_id, onActionSuccess, errorHandler);
        }
      } else {
        roleApi.getAction(it.module_id, onActionSuccess, errorHandler);
      }

    } else {
      onViewAction(it.module_id);
    }
  }

  const onViewAction = (module_id) => {
    try {
      let module = listModule.find(x => x.module_id == module_id);
      //update view actions
      let result = module.actions;
      createActionView(result);
    } catch (ex) {
      errorException(ex);
    }
  }

  const renderAction = () => {
    if (listAction.length === 0) return (<tr></tr>)
    return listAction.map((v, i) => {
      return (
        <tr key={i}>
          <th>{v.action_name}</th>
          <td>
            <input
              type="checkbox"
              value={v.action_id}
              checked={v.checked}
              onChange={(e) => { onCheckChange(e, v) }}
            />
          </td>
        </tr>
      )
    })
  }

  const onChangeInput = (value, attr) => {
    data[attr] = value;
    setData({ ...data })
  }

  const onChangeSelectedCompany = (e) => {
    setSelectedCompany(e);
  }

  const onSubmit = (e) => {
    try {
      e.preventDefault();
      let param = {
        role_name: data.role_name,
        company_id: selectedCompany.value,
        data: role
      }

      if (id) {
        roleApi.update(id, param, onSubmitSucess, errorHandler);
      } else {
        roleApi.create(param, onSubmitSucess, errorHandler);
      }
    } catch (ex) {
      errorException(ex);
    }
  }

  const onSubmitSucess = (res) => {
    alert(res.data.message);
    history.push("/roles");
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Chi tiết nhóm quyền</title>
      </MetaTags>
      {showMessage.state ?
        <AlertSimple
          message={showMessage.message}
          type={showMessage.typeShow}
          title={showMessage.title}
          confirmCallback={() => {
            setShowMessage({ state: false });
          }}
        />
        : null
      }
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Quản lý nhóm quyền</CardTitle>
          <Form>
            <div className='my-grid grid-2-column-minmax-300-400 d-grid' style={{ alignItems: "baseline" }}>
              <div>
                <Label htmlFor="role-name-Input">Tên nhóm quyền</Label>

                <Input
                  placeholder="Nhập tên"
                  type='text'
                  value={data.role_name}
                  onChange={e => onChangeInput(e.target.value, 'role_name')}
                />
              </div>
              <div>
                <Label htmlFor="role-name-Input">Hệ thống cửa hàng</Label>
                <Select
                  value={selectedCompany}
                  options={ListCompany}
                  onChange={onChangeSelectedCompany}
                />
              </div>
              <div>
                <CardTitle className="mb-4"></CardTitle>
                <Card outline color="grey" className='py-4' style={{ border: '1px solid' }}>
                  {renderModule()}
                </Card>
              </div>
              <div>

                <Table className="table table-striped table-borderless">
                  <thead>
                    <tr>
                      <th>Thao Tác</th>
                      <th className='text-center'>Lựa chọn</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderAction()}
                  </tbody>
                </Table>
              </div>
            </div>

            <div>
              <Row>
                <Col xs={12} sm="auto">
                  <button type="submit" className="btn btn-primary w-md" onClick={(e) => { onSubmit(e) }}>
                    Lưu
                  </button>
                </Col>
                <Col xs={12} sm="auto">
                  <Link to="/roles" className="btn btn-secondary w-md">
                    Thoát
                  </Link>
                </Col>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

export default Role;
