import OrderTableItem from "components/TableItem/OrderTableItem";
import React, { useEffect, useState } from "react";
import MyPagination from "../../components/Common/MyPagination";
import * as billApi from "../../apis/billApi";
import { CacheUtils } from 'Utils/CacheUtils';
import { useQuery } from "Utils/UseQuery";
import { Table, Card, CardBody, CardTitle, Row, Col, Button, Input } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import ConfirmDelete from "components/Common/ConfirmDelete";
import ConfirmRestore from "components/Common/ConfirmRestore";
import "flatpickr/dist/themes/material_blue.css"
import moment from "moment";
import MetaTags from 'react-meta-tags';
import { isRoleIn, isUserInGroup } from '../../Utils/SecurityUtils';


function ListOrder(props) {
  const [dataOrder, setDataOrder] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [restoreId, setRestoreId] = useState(null);
  const selectedShop = useSelector(state => state.SelectedShop);
  const [isShowSearch, setIsShowSearch] = useState(false);

  const [bill_number, setBill_number] = useState("");
  const [code_anh, setCode_anh] = useState("");
  const [product_code, setProduct_code] = useState("");
  const [product_name, setProduct_name] = useState("");
  const [customer_name, setCustomer_name] = useState("");
  const [customer_phone, setCustomer_phone] = useState("");
  const [user, setUser] = useState("");
  const [amount_from, setAmount_from] = useState("");
  const [amount_to, setAmount_to] = useState("");
  const [status, setStatus] = useState("");
  const [listSaleman, setListSaleman] = useState([]);
  const [from_date, setFrom_date] = useState("");
  const [to_date, setTo_date] = useState("");
  let history = useHistory();
  const showProductCatItem = () => {
    if (dataOrder.data) {
      if (dataOrder.data.length === 0) {
        return <tr></tr>;
      } else {
        return dataOrder.data.map((v, i) => {
          return (
            <OrderTableItem
              key={i}
              data={v}
              count={i + 1}
              deleteCallback={setDeleteId}
              restoreCallback={setRestoreId}
              successCallback={handleSearch}
              isKeToan={isUserInGroup(['KeToan'])}
            />
          );
        });
      }
    }
  };

  const filterToQueryString = () => {
    let queryParams = [];
    queryParams.push('user=' + encodeURIComponent(user));

    let fromDate = moment(from_date, "YYYY-MM-DD", true);
    if (fromDate.isValid())
      queryParams.push('from_date=' + fromDate.format("YYYY-MM-DD"));

    let toDate = moment(to_date, "YYYY-MM-DD", true);
    if (toDate.isValid())
      queryParams.push('to_date=' + toDate.format("YYYY-MM-DD"));

    if (Boolean(bill_number) && (!isNaN(bill_number)))
      queryParams.push('bill_number=' + encodeURIComponent(bill_number));

    queryParams.push('code_anh=' + encodeURIComponent(code_anh));
    queryParams.push('product_code=' + encodeURIComponent(product_code));
    queryParams.push('product_name=' + encodeURIComponent(product_name));
    queryParams.push('customer_name=' + encodeURIComponent(customer_name));
    queryParams.push('customer_phone=' + encodeURIComponent(customer_phone ? customer_phone : ""));
    queryParams.push('amount_from=' + encodeURIComponent(amount_from));
    queryParams.push('amount_to=' + encodeURIComponent(amount_to));
    queryParams.push('status=' + encodeURIComponent(status));
    queryParams.push('company_id=' + encodeURIComponent(selectedShop));

    let rdn = Math.floor(Math.random() * 10) + 1;
    queryParams.push('rdn=' + encodeURIComponent(rdn.toString()));
    let queryString = queryParams.join('&');
    return queryString;
  }

  const onChangePage = page => {
    let url = props.location.pathname + "?page=" + page;
    let queryString = filterToQueryString()
    url = url + '&' + queryString
    history.push(url);
  };
  
  const fetchDataSuccess = res => {
    const { data } = res.data;
    setDataOrder(data);
  };

  const fetchDataFail = res => {
    alert("Không có dữ liệu");
  };

  const fetchSalemans = () => {
    billApi.getSaleMan(fetchSalemansSuccess, fetchSalemansFail);
  };
  
  const fetchSalemansSuccess = res => {
    const { data } = res.data;
    setListSaleman(data);
    CacheUtils.setCachedSalemans(data)
  };

  const fetchSalemansFail = () => {
    alert("Không có dữ liệu");
  };

  const initSalemans = () => {
    const salemans = CacheUtils.getCachedSalemans()
    if (salemans) {
      setListSaleman(salemans)
    } else {
      fetchSalemans();
    }
  }

  useEffect(() => {
    initSalemans();
    let query = useQuery(props.location.search)
    let fromDate = moment(query.get('from_date'), 'YYYY-MM-DD').format('YYYY-MM-DD')
    setFrom_date(fromDate)
    
    let toDate = moment(query.get('to_date'), 'YYYY-MM-DD').format('YYYY-MM-DD')
    setTo_date(toDate)

    let billNumber = query.get('bill_number')
    setBill_number(billNumber)

    setCode_anh(query.get('code_anh') ?? '')
    setProduct_code(query.get('product_code') ?? '')
    setProduct_name(query.get('product_name') ?? '')
    setCustomer_name(query.get('customer_name') ?? '')
    setCustomer_phone(query.get('customer_phone') ?? '')
    setUser(query.get('user') ?? '')
    setAmount_from(query.get('amount_from') ?? '')
    setAmount_to(query.get('amount_to') ?? '')
    setStatus(query.get('status') ?? '')
  }, []);


  useEffect(() => {
    handleSearch();
  }, [props.location.search, selectedShop]);

  const onDelete = id => {
    billApi.deleteBill(id, deleteSuccess, deleteFail);
  };
  const deleteSuccess = res => {
    setDeleteId(null);
    alert("Hoá đơn đã xoá thành công");
    handleSearch();
  };
  const deleteFail = res => {
    alert(res.message);
    setDeleteId(null);
  };

  const onRestore = id => {
    billApi.restoreBill(id, restoreSuccess, restoreFail);
  };

  const restoreSuccess = res => {
    setRestoreId(null);
    alert("Khôi phục thành công");
    handleSearch();
  };

  const restoreFail = res => {
    alert(res.message);
    setRestoreId(null);
  };
  
  const handleOnSearch = () => {
    let url = props.location.pathname;
    let queryString = filterToQueryString()
    url = url + '?' + queryString
    history.push(url);
  }

  const handleSearch = () => {
    if (selectedShop === 0) return
    const params = [];
    let queryParams = useQuery(props.location.search)
    let page = queryParams.get("page");
    if (page) {
      params.push({name: "page", value: page});
    }    
    params.push({name: "user", value: queryParams.get("user")});
    params.push({name: "from_date", value: queryParams.get("from_date")});
    params.push({name: "to_date", value: queryParams.get("to_date")});
    params.push({name: "bill_number", value: queryParams.get("bill_number")});
    params.push({name: "code_anh", value: queryParams.get("code_anh")});
    params.push({name: "product_code", value: queryParams.get("product_code")});
    params.push({name: "product_name", value: queryParams.get("product_name")});
    params.push({name: "customer_name", value: queryParams.get("customer_name")});
    params.push({name: "customer_phone", value: queryParams.get("customer_phone")});
    params.push({name: "amount_from", value: queryParams.get("amount_from")});
    params.push({name: "amount_to", value: queryParams.get("amount_to")});
    params.push({name: "status", value: queryParams.get("status")});
    params.push({name: "company_id", value: queryParams.get("company_id") ?? selectedShop});
    
    billApi.getAllBill(params, fetchDataSuccess, fetchDataFail);
  }

  const handleResetFilter = () => {
    setFrom_date("");
    setTo_date("");
    setBill_number("");
    setCode_anh("");
    setProduct_code("");
    setProduct_name("");
    setCustomer_name("");
    setCustomer_phone("");
    setAmount_from("");
    setAmount_to("");
    setStatus("");
    setUser("");
  }

  const toggleSearch = () => {
    setIsShowSearch(!isShowSearch)
  }

  return (
    <div className="page-content">
    <MetaTags>
      <title>Danh sách đơn hàng</title>
    </MetaTags>
      {deleteId ? (
        <ConfirmDelete
          confirmCallback={() => {
            onDelete(deleteId);
          }}
          cancelCallback={() => {
            setDeleteId(null);
          }}
        />
      ) : (
        ""
      )}
      {restoreId ? (
        <ConfirmRestore
          confirmCallback={() => {
            onRestore(restoreId);
          }}
          cancelCallback={() => {
            setRestoreId(null);
          }}
        />
      ) : (
        ""
      )}
      <Card>
        <CardBody>
          <Card color='light'>
            <CardBody>
              <Row className="justify-content-between">
                <Col xs="auto" sm="auto"><CardTitle className="h4">Danh sách hóa đơn</CardTitle></Col>
                <Col xs="auto" sm="auto">
                  <Link to="/orders2/create">
                    {" "}
                    <Button color="primary" className="px-3 rounded-pill">
                      <i className="fas fa-plus mr-2"></i> Thêm mới
                    </Button>
                  </Link>
                </Col>
              </Row>
              {
                isShowSearch ? 
                <Row className='align-items-end'>
                  <Col xs={3}>
                    <label className='body-2 font-weight-bold'>Từ ngày</label>
                    <Input type='date'
                      className="form-control"
                      value={from_date}
                      onChange={(e) => setFrom_date(e.target.value)} />
                  </Col>

                  <Col xs={3}>
                    <label className='body-2 font-weight-bold'>Đến ngày</label>
                    <Input type='date'
                      className="form-control"
                      value={to_date}
                      onChange={(e) => setTo_date(e.target.value)} />
                  </Col>

                  <Col xs={3}>
                    <label className='body-2 font-weight-bold'>Tìm theo số HD</label>
                    <Input type='number'
                      className="form-control"
                      value={bill_number}
                      onChange={(e) => setBill_number(e.target.value)} />
                  </Col>

                  <Col xs={3}>
                    <label className='body-2 font-weight-bold'>Tìm theo code ảnh</label>
                    <Input type='text'
                      className="form-control"
                      value={code_anh}
                      onChange={(e) => setCode_anh(e.target.value)} />
                  </Col>
                  
                  <Col xs={3}>
                    <label className='body-2 font-weight-bold'>Tìm theo mã sp</label>
                    <Input type='text'
                      className="form-control"
                      value={product_code}
                      onChange={(e) => setProduct_code(e.target.value)} />
                  </Col>
                  
                  <Col xs={3}>
                    <label className='body-2 font-weight-bold'>Tìm theo tên sp</label>
                    <Input type='text'
                      className="form-control"
                      value={product_name}
                      onChange={(e) => setProduct_name(e.target.value)} />
                  </Col>

                  <Col xs={3}>
                    <label className='body-2 font-weight-bold'>Tìm theo khách</label>
                    <Input type='text'
                      className="form-control"
                      value={customer_name}
                      onChange={(e) => setCustomer_name(e.target.value)} />
                  </Col>

                  <Col xs={3}>
                    <label className='body-2 font-weight-bold'>Tìm theo SĐT</label>
                    <Input type='number'
                      className="form-control"
                      placeholder="Gõ đầy đủ số phone"
                      value={customer_phone}
                      onChange={(e) => setCustomer_phone(e.target.value)} />
                  </Col>

                  <Col xs={3}>
                    <label className='body-2 font-weight-bold'>Tìm theo NV</label>
                    <Input type='select'
                      className="form-control"
                      value={user}
                      onChange={(e) => setUser(e.target.value)}
                    >
                      <option value={""}>Chọn nhân viên</option>
                      {
                        listSaleman.map((item, i) => (
                          <option value={item.user_id} key={i}>{item.fullname} - {item.username}</option>
                        ))
                      }
                    </Input>
                  </Col>

                  <Col xs={3}>
                    <label className='body-2 font-weight-bold'>Số tiền từ</label>
                    <Input type='number'
                      className="form-control"
                      value={amount_from}
                      onChange={(e) => setAmount_from(e.target.value)} />
                  </Col>

                  <Col xs={3}>
                    <label className='body-2 font-weight-bold'>Số tiền đến</label>
                    <Input type='number'
                      className="form-control"
                      value={amount_to}
                      onChange={(e) => setAmount_to(e.target.value)} />
                  </Col>

                  <Col xs={3}>
                    <label className='body-2 font-weight-bold'>
                      Tìm theo trạng thái
                    </label>
                    <Input type='select'
                      className="form-control"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value={""}>Chọn trạng thái</option>
                      <option value={0}>Đã xóa</option>
                      <option value={1}>Bình thường</option>
                    </Input>
                  </Col>

                  <Col xs={12} lg="auto">
                    <Button color="primary" className="mr-3 mt-3" onClick={handleOnSearch}>
                      Tìm kiếm
                    </Button>
                    <Button color="secondary" className="mr-3 mt-3" onClick={handleResetFilter}>
                      Reset tìm kiếm
                    </Button>
                  </Col>
                </Row>
                : null
              }
              <div className='tag color-primary font-weight-bold mt-3' style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}} onClick={() => toggleSearch()}>
                {
                  isShowSearch ? "Đóng tìm kiếm" : "Tìm kiếm"
                } 
                {
                  isShowSearch
                  ? <i className="bx bx bx-chevron-up ml-2" />
                  : <i className="bx bx bx-chevron-down ml-2" />
                }
              </div>
            </CardBody>
          </Card>
          <div className="table-responsive">
            <Table className="table mb-3">
              <thead>
                <tr>
                  <th>Ngày</th>
                  <th>Số HD</th>
                  <th>Khách</th>
                  <th>Phone</th>
                  <th>NV</th>
                  <th>Tổng</th>
                  <th>Tổng CK</th>
                  <th>Tổng sau CK</th>
                  <th>Trạng thái</th>
                  <th>Hành Động</th>
                </tr>
              </thead>
              <tbody>{showProductCatItem()}</tbody>
            </Table>
          </div>
          <MyPagination
            startPage={dataOrder.current_page ? dataOrder.current_page : 1}
            totalItemsCount={dataOrder.total ? dataOrder.total : 5}
            itemsCountPerPage={dataOrder.per_page ? dataOrder.per_page : 10}
            callback={onChangePage}
          />
        </CardBody>
      </Card>
    </div>
  );
}
ListOrder.propTypes = {
  location: PropTypes.object,
};

export default withRouter(ListOrder);
