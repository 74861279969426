import React from "react"
import { PropTypes } from 'prop-types';


ReportButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
}

function ReportButton({ label, onClick }) {
    return (
            <button
                className="btn btn-primary mr-2"
                type="button"
                style={{ cursor: "pointer" }}
                onClick={() => onClick()}
            >
                {label}
            </button>
    );
}

export default ReportButton;