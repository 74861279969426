import { showMessage } from "Utils/global";
import { useState, useEffect } from "react";
import * as categoryApi from '../../apis/categoryApi';

const useFetchWebCategories = (selectedShop) => {
  const [webCategoriesTree, setWebCategoriesTree] = useState([]);  
  
  useEffect(() => {
    if (selectedShop > 0) {
      fetchTreeWebCategory();
    }
  }, [selectedShop])

  const fetchTreeWebCategory = () => {
    let params = [
      { name: 'company_id', value: selectedShop },
    ]

    categoryApi.getTreeWebCategory(params, fetchTreeWebCatSuccess, showMessage)
  }
  const fetchTreeWebCatSuccess = (res) => {
    let webCategories = res.data.data
    setWebCategoriesTree(webCategories);
  }

  return webCategoriesTree;
};

export default useFetchWebCategories;
