import React, { useState, useEffect } from 'react';
import { Card, CardBody, Table, Modal, ModalHeader, ModalBody, Label, TabContent, TabPane } from 'reactstrap';
import MyPagination from "../../components/Common/MyPagination";
import { Container, Col, Row, Input, Nav, NavItem, NavLink } from 'reactstrap';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getMessage } from 'Utils/ErrMessageUtils';
import * as inventoriesApi from './../../apis/inventoriesApi';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { observer } from 'mobx-react-lite';
import { getUser } from "../../constants/function";
import authStore from '../../store/authStore';
import { useStore } from '../../store/hooks';
import { message } from 'antd';
import classnames from "classnames"
import MetaTags from 'react-meta-tags';
import { API_HOST_NAME } from './../../constants/SystemConstant';
import { inUserGroup } from 'helpers/function';
import { isUserInGroup, isWarehouseManager } from 'Utils/SecurityUtils';

const Inventory = observer((props) => {
  const AuthStore = useStore(authStore);
  const selectedShop = useSelector(state => state.SelectedShop);
  const [product_name, setProductName] = useState("");
  const [togglemodal, setTogglemodal] = useState(false);
  const [modal, setModel] = useState(false);
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [dataView, setDataView] = useState([]);
  const [additionalData, setAdditionalData] = useState({});
  const [expired_date, setExpired_date] = useState("");
  const [customer_phone, setCustomer_phone] = useState("");
  const [customer_name, setCustomer_name] = useState("");
  const [qty, setQty] = useState("");
  const [cancel_note, setCancel_note] = useState("");
  const [isShowCancel, setIsShowCancel] = useState(false);
  const [isShowAddToCart, setIsShowAddToCart] = useState(false);
  const [dataProductStoreHd, setDataProductStoreHd] = useState({});
  const [dataStoreDelete, setDataStoreDelete] = useState({});
  const [reservationsDetail, setReservationsDetail] = useState([]);
  const [listCart, setListCart] = useState([]);
  const [dataUser, setDataUser] = useState({});
  const [activeTab, setActiveTab] = useState("1");
  const [searchButtonText, setSearchButtonText] = useState("Tìm kiếm");
  const [advsButtonText, setAdvsButtonText] = useState("Tìm kiếm");

  // advs = advance search
  const [advsProductCode, setAdvsProductCode] = useState("");
  const [advsProductName, setAdvsProductName] = useState("");
  const [advsProductCategory, setAdvsProductCategory] = useState("");
  const [advsProductSize, setAdvsProductSize] = useState("");
  const [advsProductColor, setAdvsProductColor] = useState("");

  const [advsProductCodeOp, setAdvsProductCodeOp] = useState("contains");
  const [advsProductNameOp, setAdvsProductNameOp] = useState("contains");
  const [advsProductCategoryOp, setAdvsProductCategoryOp] = useState("contains");
  const [advsProductSizeOp, setAdvsProductSizeOp] = useState("equals");
  const [advsProductColorOp, setAdvsProductColorOp] = useState("equals");

  const [productStoreState, setProductStoreState] = useState([]);
  const [storeDateSate, setStoreDateSate] = useState([]);

  const operators = [
    { label: "Chứa", value: "contains" },
    { label: "Không chứa", value: "notContains" },
    { label: "Bắt đầu", value: "startWith" },
    { label: "Không bắt đầu", value: "notStartWith" },
    { label: "Kết thúc", value: "endWith" }
  ];

  const attOperators = [
    { label: "Bằng", value: "equals" },
    { label: "Chứa", value: "contains" },
    { label: "Không chứa", value: "notContains" },
    { label: "Bắt đầu", value: "startWith" },
    { label: "Không bắt đầu", value: "notStartWith" },
    { label: "Kết thúc", value: "endWith" }
  ];


  useEffect(() => {
    const listItem = JSON.parse(localStorage.getItem('cart'))
    if (listItem) {
      setListCart(listItem);
    }
    const data = getUser();
    setDataUser(data);
  }, [selectedShop]);

  const doInventories = () => {
    getInventories(1)
  }

  const getInventories = (page) => {

    if ((product_name === '')
      && (size === '')
      && (color === ''))
      return

    const params = [
      { name: 'company_id', value: selectedShop },
      { name: 'product_name', value: product_name },
      { name: 'size', value: size },
      { name: 'color', value: color },
      { name: "page", value: page }
    ]
    inventoriesApi.getInventories(params, getDataViewHotFix, showErr);
  }

  const doAdvsInventories = () => {
    getAdvsInventories(1)
  }

  const getAdvsInventories = (page) => {

    if ((advsProductCode === '')
      && (advsProductName === '')
      && (advsProductCategory === '')
      && (advsProductSize === '')
      && (advsProductColor === ''))
      return

    const params = [
      { name: 'company_id', value: selectedShop },
      { name: 'code', value: advsProductCode },
      { name: 'code_op', value: advsProductCodeOp },
      { name: 'name', value: advsProductName },
      { name: 'name_op', value: advsProductNameOp },
      { name: 'cat', value: advsProductCategory },
      { name: 'cat_op', value: advsProductCategoryOp },
      { name: 'size', value: advsProductSize },
      { name: 'size_op', value: advsProductSizeOp },
      { name: 'color', value: advsProductColor },
      { name: 'color_op', value: advsProductColorOp },
      { name: "page", value: page }
    ]
    inventoriesApi.getAdvsInventories(params, getDataViewHotFix, showErr);
  }

  const getDataView = (res) => {
    setDataView(res.data.data.data);
    setAdditionalData(res.data.data);

    let total = res.data.data.total ? res.data.data.total.toString() : '0'
    let buttonText = `Tìm kiếm (Tìm thấy ${total} sản phẩm)`

    if (activeTab === "1")
      setSearchButtonText(buttonText)
    else
      setAdvsButtonText(buttonText)

    setProductStoreState([]);
    setStoreDateSate([]);
  }


  const getDataViewHotFix = (res) => {
    setDataView(res.data.data);

    const paginationData = {}
    paginationData.current_page = res.data.current_page
    paginationData.total_row = res.data.total_row
    paginationData.total = res.data.total
    paginationData.per_page = res.data.per_page

    setAdditionalData({ ...paginationData });

    let total = res.data.total ? res.data.total.toString() : '0'
    let buttonText = `Tìm kiếm (Tìm thấy ${total} sản phẩm)`

    if (activeTab === "1")
      setSearchButtonText(buttonText)
    else
      setAdvsButtonText(buttonText)

    setProductStoreState([]);
    setStoreDateSate([]);
  }

  const showErr = (res) => {
    alert(getMessage(res.message));
  }

  const onChangePage = page => {
    if (activeTab === "1")
      getInventories(page)
    else
      getAdvsInventories(page)
  }

  const updateProductStore = (product_code) => {
    const params = [
      {
        name: 'company_id',
        value: selectedShop
      },
      {
        name: 'product_code',
        value: product_code
      }
    ]
    inventoriesApi.getInventoriesDetail(params, fetchDataProductStoreSuccess, fetchDataProductStoreFail);
  }

  const fetchDataProductStoreSuccess = (res) => {
    setProductStoreState(res.data.data);
    setStoreDateSate([]);
  }

  const fetchDataProductStoreFail = (res) => {
    alert(getMessage(res.message));
    setProductStoreState([]);
  }

  const updateStoreData = (data) => {
    const params = [
      { name: 'company_id', value: selectedShop },
      { name: 'product_code', value: data?.product_code },
      { name: 'warehouse_code', value: data?.warehouse_code }
    ]
    inventoriesApi.getReservationsDetail(params, fetchDataStoreDateSateSuccess, fetchDataStoreDateSateFail);
  }
  const fetchDataStoreDateSateSuccess = (res) => {
    setStoreDateSate(res.data.data);
  }

  const fetchDataStoreDateSateFail = (res) => {
    alert(getMessage(res.message));
    setStoreDateSate([]);
  }

  const handleSubmitReservations = (value) => {
    const params = {
      ...value,
      product_code: dataProductStoreHd?.product_code,
      warehouse_code: dataProductStoreHd?.warehouse_code,
      company_id: selectedShop,
      customer_phone: "0" + value.customer_phone.toString()
    }
    inventoriesApi.onAddReservations(params, addReserSuccess, addReserFail);
  }
  const addReserSuccess = (res) => {
    setTogglemodal(false);
    updateStoreData(dataProductStoreHd);
    setDataProductStoreHd({});
  }
  const formatCash = (str) => {
    if (str) {
      return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else {
      return str
    }
  }
  const addReserFail = (res) => {
    alert(getMessage(res.data.message));
    setDataProductStoreHd({});
  }

  const getStoreDataDelete = (data) => {
    setDataStoreDelete(data);
    setIsShowCancel(true);
  }

  const handleDeleteItem = (value) => {
    const params = {
      ...value,
      id: dataStoreDelete.id
    }
    inventoriesApi.deleteReservations(params, deleteSuccess, deleteFail);
  }
  const deleteSuccess = (res) => {
    setIsShowCancel(false);
    updateStoreData(dataStoreDelete);
    setDataStoreDelete({});
  }
  const deleteFail = (res) => {
    alert(getMessage(res.data.message));
  }
  const showInventory = () => {
    if (dataView.length === 0) {
      return (<tr></tr>)
    }
    return dataView.map((v, i) => {
      let thumb_file = v.thumb_file.startsWith('http') ? v.thumb_file : API_HOST_NAME + v.thumb_file
      let slide_file = v.slide_file.startsWith('http') ? v.slide_file : API_HOST_NAME + v.slide_file

      return (
        <tr key={i} onClick={() => updateProductStore(v.product_code)}>
          <td><a href={slide_file} rel="noreferrer" target="_blank"><img className="product-image" src={thumb_file} /></a></td>
          <td className='font-weight-bold color-primary'>{v.product_code}</td>
          <td className='color-primary body-2'>{v.product_name}</td>
          <td className=' body-2'>{v.category_name}</td>
          <td className=' body-2'>{v.product_desc}</td>
          <td className=' body-2'>{formatCash(v.price)}</td>
          <td className=' body-2'>{formatCash(v.sale_price)}</td>
          {
            (isUserInGroup(['Admin', 'KeToan']) || isWarehouseManager())
              ? <td className=' body-2'>{formatCash(v.original_price)}</td>
              : null
          }
          <td className=' body-2'>{v.qty}</td>
        </tr>
      )
    })
  }
  const showStoreData = () => {
    if (storeDateSate.length === 0) {
      return (<tr></tr>)
    }
    return storeDateSate.map((v, i) => {
      return (
        <tr key={i}>
          <td>{v.warehouse_code}</td>
          <td>{v.qty}</td>
          <td>{v.customer_name}</td>
          <td>{v.customer_phone}</td>
          <td>{v.expired_date}</td>
          <td>{v.user_info.username}</td>
          <td><Button color='danger' className='btn-sm' onClick={() => getStoreDataDelete(v)}>Hủy</Button></td>
        </tr>
      )
    })
  }
  const getDataProductStore = (data) => {
    setTogglemodal(true);
    setDataProductStoreHd(data);
  }
  const addDataToCart = (data) => {
    setIsShowAddToCart(true);
    setDataProductStoreHd(data);
  }
  const handleAddToCart = (value) => {
    const item = {
      ...value,
      product_name: dataProductStoreHd?.product_name,
      company_id: selectedShop,
      product_id: dataProductStoreHd?.product_id,
      unit_code: dataProductStoreHd?.unit_code,
      price: dataProductStoreHd?.price,
      amount: dataProductStoreHd?.price * value.qty,
      final_amount: (dataProductStoreHd?.price * value.qty) - value.discount
    }
    const data = [...listCart, item]
    setListCart(data);
    localStorage.setItem('cart', JSON.stringify(data))
    AuthStore.data = data
    message.success('Thêm hóa đơn thành công');
    setIsShowAddToCart(false);
  }
  const showProductStore = () => {
    if (productStoreState.length === 0) {
      return (<tr></tr>)
    }
    return productStoreState.map((v, i) => {
      return (
        <tr key={i} onClick={() => updateStoreData(v)}>
          <td>{v.warehouse_code}</td>
          <td>{v.qty}</td>
          <td>
            <Row>
              <Col xs="auto">
                <Button color='primary' className='btn-sm' onClick={() => getDataProductStore(v)}>
                  Giữ hàng
                </Button>
              </Col>
              <Col xs="auto">
                <Button color='success' disabled={v.qty < 1} className='btn-sm' onClick={() => addDataToCart(v)}>
                  Thêm HĐ
                </Button>
              </Col>
            </Row>
          </td>
        </tr>
      )
    })
  }

  const handleKeyPress = (target) => {
    if(target.charCode==13){
      doInventories(); //alert('Enter clicked!!!');    
    } 
  }

  const renderFormTimKiemNhanh = () => {
    return (
      <>
        <Row className='mb-3'>
          <Col xs={12} lg={4}>
            <label className='body-2 font-weight-bold'>
              Tìm theo mã/tên /nhóm:
            </label>
            <Input type='text'
              className="form-control"
              onChange={(e) => setProductName(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </Col>
          <Col xs={6} lg={4}>
            <label className='body-2 font-weight-bold'>
              Tìm theo size:
            </label>
            <Input type='text'
              className="form-control"
              onChange={(e) => setSize(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </Col>
          <Col xs={6} lg={4}>
            <label className='body-2 font-weight-bold'>
              Tìm theo màu:
            </label>
            <Input type='text'
              className="form-control"
              onChange={(e) => setColor(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </Col>
        </Row>
        <Row>
          <Button color="primary" onClick={doInventories}>
            {searchButtonText}
          </Button>
        </Row>
      </>
    )
  }

  const renderFormTimKiemNangCao = () => {
    return (
      <>
        <Row className='mb-2'>
          <Col lg={12} xl={6}>
            <Row>
              <Label lg={2} xl={2} className="col-form-label">Mã SP</Label>
              <Col lg={2} xl={2}>
                <Input
                  type='select'
                  id="product-code"
                  className="form-control"
                  value={advsProductCodeOp}
                  onChange={(e) => setAdvsProductCodeOp(e.target.value)}
                >
                  {
                    operators.map((item, i) => (
                      <option value={item.value} key={i}>{item.label}</option>
                    ))
                  }
                </Input>
              </Col>
              <Col lg={8} xl={8}>
                <Input type='text'
                  className="form-control"
                  onChange={(e) => setAdvsProductCode(e.target.value)} />
              </Col>
            </Row>
          </Col>
          <Col lg={12} xl={6}>
            <Row>
              <Label lg={2} xl={2} className="col-form-label">Tên SP</Label>
              <Col lg={2} xl={2}>
                <Input
                  type='select'
                  id="product-name"
                  className="form-control"
                  value={advsProductNameOp}
                  onChange={(e) => setAdvsProductNameOp(e.target.value)}
                >
                  {
                    operators.map((item, i) => (
                      <option value={item.value} key={i}>{item.label}</option>
                    ))
                  }
                </Input>
              </Col>
              <Col lg={8} xl={8}>
                <Input type='text'
                  className="form-control"
                  onChange={(e) => setAdvsProductName(e.target.value)} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col lg={12} xl={6}>
            <Row>
              <Label lg={2} xl={2} className="col-form-label">Size</Label>
              <Col lg={2} xl={2}>
                <Input
                  type='select'
                  id="product-size"
                  className="form-control"
                  value={advsProductSizeOp}
                  onChange={(e) => setAdvsProductSizeOp(e.target.value)}
                >
                  {
                    attOperators.map((item, i) => (
                      <option value={item.value} key={i}>{item.label}</option>
                    ))
                  }
                </Input>
              </Col>
              <Col lg={8} xl={8}>
                <Input type='text'
                  className="form-control"
                  onChange={(e) => setAdvsProductSize(e.target.value)} />
              </Col>
            </Row>
          </Col>
          <Col lg={12} xl={6}>
            <Row>
              <Label lg={2} xl={2} className="col-form-label">Màu</Label>
              <Col lg={2} xl={2}>
                <Input
                  type='select'
                  id="product-color"
                  className="form-control"
                  value={advsProductColorOp}
                  onChange={(e) => setAdvsProductColorOp(e.target.value)}
                >
                  {
                    attOperators.map((item, i) => (
                      <option value={item.value} key={i}>{item.label}</option>
                    ))
                  }
                </Input>
              </Col>
              <Col lg={8} xl={8}>
                <Input type='text'
                  className="form-control"
                  onChange={(e) => setAdvsProductColor(e.target.value)} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className='mb-2'>
          <Col lg={12} xl={6}>
            <Row>
              <Label lg={2} xl={2} className="col-form-label">Nhóm SP</Label>
              <Col lg={2} xl={2}>
                <Input
                  type='select'
                  id="product-cat"
                  className="form-control"
                  value={advsProductCategoryOp}
                  onChange={(e) => setAdvsProductCategoryOp(e.target.value)}
                >
                  {
                    operators.map((item, i) => (
                      <option value={item.value} key={i}>{item.label}</option>
                    ))
                  }
                </Input>
              </Col>
              <Col lg={8} xl={8}>
                <Input type='text'
                  className="form-control"
                  onChange={(e) => setAdvsProductCategory(e.target.value)}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={12} xl={6}>
            <Row>
              <Label lg={2} xl={2} className="col-form-label"></Label>
              <Col lg={2} xl={2}>
              </Col>
              <Col lg={8} xl={8}>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Button color="primary" onClick={doAdvsInventories} >
            {advsButtonText}
          </Button>
        </Row>
      </>
    )
  }

  const resetForm = (status) => {
    setActiveTab(status)
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Tra tồn</title>
      </MetaTags>
      <Container fluid={true}>
        <h4 className='mb-4'>
          TỒN KHO
        </h4>
        <Card>
          <CardBody>

            <button
              className={`btn ${activeTab === "1" ? 'btn-primary' : ""}`}
              onClick={() => {
                resetForm("1")
              }}
              type="button"
              style={{ cursor: "pointer", borderRadius: '0' }}
            >
              Tìm kiếm nhanh
            </button>
            <button
              className={`btn ${activeTab === "2" ? 'btn-primary' : ""}`}
              onClick={() => {
                resetForm("2")
              }}
              style={{ cursor: "pointer", borderRadius: '0' }}
            >
              Tìm kiếm nâng cao
            </button>
            <TabContent activeTab={activeTab} className="p-3 text-muted mb-3">
              <TabPane tabId="1">
                {
                  renderFormTimKiemNhanh()
                }
              </TabPane>
              <TabPane tabId="2">
                {
                  renderFormTimKiemNangCao()
                }
              </TabPane>
            </TabContent>
            {/*
                        */}
            <Row>
              <Table id='tbl-inventory'>
                <thead className="table-light">
                  <tr>
                    <th>Ảnh</th>
                    <th>Mã sản phẩm</th>
                    <th style={{ width: "350px" }}>Tên sản phẩm</th>
                    <th>Nhóm SP</th>
                    <th>Mô tả SP</th>
                    <th>Giá bán</th>
                    <th>Giá KM</th>
                    {
                      (isUserInGroup(['Admin', 'KeToan']) || isWarehouseManager()) ? <th>Giá vốn</th> : null
                    }
                    <th>Tồn</th>
                  </tr>
                </thead>
                <tbody>
                  {showInventory()}
                </tbody>
              </Table>
            </Row>
            <Row>
              <MyPagination
                startPage={additionalData.current_page ? parseInt(additionalData.current_page) : 1}
                totalItemsCount={additionalData.total ? parseInt(additionalData.total) : 5}
                itemsCountPerPage={additionalData.per_page ? parseInt(additionalData.per_page) : 10}
                callback={onChangePage} />
            </Row>
            <Row>
              <Col xs={12} lg={4}>
                <Table>
                  <thead className="table-light">
                    <tr>
                      <th>Mã kho</th>
                      <th>SL tồn kho</th>
                      <th>Thao tác</th>
                    </tr>
                  </thead>
                  <tbody>
                    {showProductStore()}
                  </tbody>
                </Table>
              </Col>
              <Col xs={12} lg>
                <Table>
                  <thead className="table-light">
                    <tr>
                      <th>Các yêu cầu giữ hàng</th>
                      <th>SL giữ</th>
                      <th>Tên khách</th>
                      <th>SĐT</th>
                      <th>Đến ngày</th>
                      <th>Nhân viên</th>
                      <th>Thao tác</th>
                    </tr>
                  </thead>
                  <tbody>
                    {showStoreData()}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
      {/* model tạo thông tin giữ hàng */}
      <Modal
        isOpen={togglemodal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabindex="-1"
      >
        <div className="modal-content">
          <ModalHeader>Thông tin giữ hàng</ModalHeader>
          <ModalBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                expired_date: (expired_date) || "",
                customer_name: (customer_name) || "",
                customer_phone: (customer_phone) || "",
                qty: (qty) || ""
              }}
              validationSchema={Yup.object().shape({
                expired_date: Yup.string().required(
                  "Ngày không được để trống"
                ),
                customer_name: Yup.string().required(
                  "Tên khách hàng không được để trống"
                ),
                customer_phone: Yup.string().required(
                  "Số điện thoại không được để trống"
                ),
                qty: Yup.string()
                  .test('val', `Không được lớn hơn ${dataProductStoreHd?.qty}`, val => val <= dataProductStoreHd?.qty)
                  .required(
                    "Số lượng không được để trống"
                  ),
              })}
              onSubmit={values => {
                handleSubmitReservations(values);
              }}
            >
              {({ errors, status, touched }) => (
                <Form>
                  <div className="mb-3">
                    <Label>Ngày</Label>
                    <Field
                      name="expired_date"
                      label="Ngày"
                      type="date"
                      className={
                        "form-control" +
                        (errors.expired_date && touched.expired_date
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="expired_date"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Tên khách hàng</Label>
                    <Field
                      name="customer_name"
                      label="Tên khách hàng"
                      placeholder="Nhập tên khách hàng"
                      type="text"
                      className={
                        "form-control" +
                        (errors.customer_name && touched.customer_name
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="customer_name"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Số điện thoại</Label>
                    <Field
                      name="customer_phone"
                      label="Số điện thoại"
                      placeholder="Nhập số điện thoại"
                      type="number"
                      className={
                        "form-control" +
                        (errors.customer_phone && touched.customer_phone
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="customer_phone"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Số lượng</Label>
                    <Field
                      name="qty"
                      label="Số lượng"
                      type="number"
                      className={
                        "form-control" +
                        (errors.qty && touched.qty
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="qty"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>



                  <div className="d-flex flex-wrap gap-2">
                    <Button type="submit" color="primary">
                      Hoàn tất
                    </Button>{" "}
                    <Button type="reset" color="secondary" onClick={() => setTogglemodal(!togglemodal)}>
                      Đóng
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
      {/* Kết thúc thêm giữ hàng */}

      {/* Model thêm hóa đơn */}
      <Modal
        isOpen={isShowAddToCart}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabindex="-1"
      >
        <div className="modal-content">
          <ModalHeader>Thêm hóa đơn</ModalHeader>
          <ModalBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                qty: "",
                discount: "",
                warehouse_code: dataProductStoreHd ? dataProductStoreHd?.warehouse_code : "",
                product_code: dataProductStoreHd ? dataProductStoreHd?.product_code : ""
              }}
              validationSchema={Yup.object().shape({
                qty: Yup.string()
                  .required(
                    "Số lượng không được để trống"
                  )
                  .test('val', `Không được lớn hơn ${dataProductStoreHd?.qty}`, val => val <= dataProductStoreHd?.qty),
                discount: Yup.string()
                  .required(
                    "Chiết khấu không được để trống"
                  )
              })}
              onSubmit={values => {
                handleAddToCart(values);
              }}
            >
              {({ errors, status, touched }) => (
                <Form>
                  <div className="mb-3">
                    <Label>Mã sản phẩm</Label>
                    <Field
                      name="product_code"
                      label="Mã sản phầm"
                      type="text"
                      className={"form-control"}
                      disabled={true}
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Mã kho</Label>
                    <Field
                      name="warehouse_code"
                      label="Mã kho"
                      type="text"
                      disabled={true}
                      className={"form-control"}
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Số lượng</Label>
                    <Field
                      name="qty"
                      label="Số lượng"
                      type="number"
                      className={
                        "form-control" +
                        (errors.qty && touched.qty
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="qty"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Chiết khấu</Label>
                    <Field
                      name="discount"
                      label="Chiết khấu"
                      type="number"
                      className={
                        "form-control" +
                        (errors.discount && touched.discount
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="discount"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="d-flex flex-wrap gap-2">
                    <Button type="submit" color="primary">
                      Thêm Hóa đơn
                    </Button>{" "}
                    <Button type="reset" color="secondary" onClick={() => setIsShowAddToCart(!isShowAddToCart)}>
                      Đóng
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
      {/* Kết thúc thêm hóa đơn */}

      {/* Model hủy đơn hàng */}
      <Modal
        isOpen={isShowCancel}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabindex="-1"
      >
        <div className="modal-content">
          <ModalHeader>Nhập lý do muốn xóa</ModalHeader>
          <ModalBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                cancel_note: (cancel_note) || ""
              }}
              validationSchema={Yup.object().shape({
                cancel_note: Yup.string().required(
                  "Lý do không được để trống"
                )
              })}
              onSubmit={values => {
                handleDeleteItem(values);
              }}
            >
              {({ errors, status, touched }) => (
                <Form>
                  <div className="mb-3">
                    <Label>Lý do</Label>
                    <Field
                      name="cancel_note"
                      label="Lý do"
                      type="text"
                      className={
                        "form-control" +
                        (errors.cancel_note && touched.cancel_note
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="cancel_note"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="d-flex flex-wrap gap-2">
                    <Button type="submit" color="primary">
                      Xóa
                    </Button>{" "}
                    <Button type="reset" color="secondary" onClick={() => setIsShowCancel(!isShowCancel)}>
                      Đóng
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
})

export default Inventory;
